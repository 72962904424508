@import "@Static/css/common";

.link {
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 1,
                "color": "244, 115, 84",
            ),
        )
    );
    text-decoration: none;
}

.navButtonColorScheme {
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 1,
                "color": "244, 115, 84",
            ),
            "bgc": (
                "key": 1,
                "opacity": 0.2,
                "color": "255, 255, 255",
            ),
            "boc": (
                "key": 1,
                "color": "0, 0, 0",
            ),
        )
    );
}

.root {
    background-color: #f8f7f2ea;
    box-shadow: 0px 1px 4px 0px #0005;
    position: fixed;
    z-index: 300;
    width: 100%;
    backdrop-filter: blur(6px);
    left: 0;
}
.container {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    max-width: 1300px;
    margin: auto;
}
.flexGrow {
    flex-grow: 1;
}
.navTitle {
    font-size: 1.6em;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 3px;
    @extend .word-break;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-decoration: none !important;
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 3,
            ),
        )
    );
}

.navIconButtons {
    display: flex;
    cursor: pointer;

    border: 2px solid;

    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 1,
                "color": (
                    255,
                    91,
                    0,
                ),
            ),
            "boc": (
                "key": 1,
                "color": (
                    255,
                    91,
                    0,
                ),
            ),
        )
    );

    border-radius: 15px;
    padding: 8px 15px;
    margin-left: 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.15s ease all;
    &:hover {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                    "color": (
                        255,
                        91,
                        0,
                    ),
                    "opacity": 0.1,
                ),
            )
        );
    }
}

.navButton {
    border: 2px solid;
    padding: 0.3rem 1rem;
    margin-left: 1rem;
    cursor: pointer;
    @extend .navButtonColorScheme;
}

.notificationIconColorScheme {
    background-color: transparent;
}

@media (max-width: 1024px) {
    .container {
        padding: 0 10px;
    }
    .mobileDropdown {
        display: block;
        font-size: 1.5em;
        cursor: pointer;
    }
    .mobileMenu {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding: 0;
    }
    .root {
        flex-direction: column;
        padding: 0;
    }
    .navTitle {
        margin: 20px 0;
        font-size: 16px;
        letter-spacing: 2px;
    }

    .mobileIcon {
        display: block;
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                    "color": (
                        255,
                        91,
                        0,
                    ),
                ),
            )
        );
        color: white;
        position: relative;
        padding: 10px 13.5px 10px 16.5px;
        border-radius: 50%;
        margin: 10px 0 10px 10px;
    }
    .navIconButtons {
        margin-left: 0.5rem;
    }
    .flexGrow {
        flex-grow: 0;
    }
}
